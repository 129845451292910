import React from "react";
import Page from "../components/Page";

const Resume = () => {
  return (
    <Page>
      <h1>Resume</h1>
    </Page>
  );
};

export default Resume;
